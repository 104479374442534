import styled from "styled-components"

export const StyledAwards = styled.div`
         font-size: 16px;

         li {
           list-style: none;

           i {
             margin-right: 5px;
             color: #f16d2a;
           }
         }
       `
