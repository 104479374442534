import styled from "styled-components"
// import { device } from "./MediaQueries"

export const StyledBio = styled.div`
  
  img {
    border-radius: 5px;
  }

  .bio,
  .bio-image {
    margin-bottom: 50px;
  }

`
